import clsx from 'clsx'
import { memo } from 'react'

import Avatar from '@/atoms/Avatar/Avatar'
import Link from '@/atoms/Link'
import Creator from '@/ts/Creator'

function CreatorReference({ className, creator }: CreatorReferenceProps) {
  const { avatar, nickname, slug } = creator

  return (
    <Link
      className={clsx(
        'flex items-center space-x-2 text-sm transition-colors overflow-hidden text-gray-100 hover:underline max-w-max',
        className
      )}
      href={{ pathname: '/creators/[slug]', query: { slug } }}
    >
      <Avatar nickname={nickname} src={avatar} size={20} />
      <span className="overflow-hidden words-break text-ellipsis">
        {nickname}
      </span>
    </Link>
  )
}

interface CreatorReferenceProps {
  className?: string
  creator: Creator
}

export default memo(CreatorReference)
