import clsx from 'clsx'

import IconArrowRounded from '@/assets/svg/default/icons/icon-arrow-rounded.svg'
import IconArrowPrimary from '@/assets/svg/purge/icons/icon-arrow.svg'
import IconArrowSecondary from '@/assets/svg/purge/icons/icon-arrow-wide.svg'
import IconChevron from '@/assets/svg/purge/icons/icon-chevron.svg'

import { ArrowProps } from './ts'

const arrows: Record<string, JSX.Element> = {
  chevron: <IconChevron />,
  primary: <IconArrowPrimary />,
  secondary: <IconArrowSecondary />,
  rounded: <IconArrowRounded />,
}

function Arrow({
  className,
  direction = 'down',
  variant = 'chevron',
}: ArrowProps): JSX.Element {
  return (
    <span
      role="img"
      className={clsx(
        'transform block',
        {
          '-scale-1': direction === 'up',
          '-rotate-90': direction === 'right',
          'rotate-90': direction === 'left',
        },
        className
      )}
    >
      {arrows[variant]}
    </span>
  )
}

export default Arrow
