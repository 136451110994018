import clsx from 'clsx'
import Image from 'next/image'
import { memo } from 'react'

import Icon from '@/atoms/Icon'
import useLikedProducts from '@/graphql/hooks/useLikedProducts'
import generateBlurDataURL from '@/helpers/generateBlurDataURL'
import css from '@/molecules/ProductCard/css/index.module.css'

function Cover(props: CoverProps) {
  const { src, name, layout } = props
  const productId = props?.id ?? -1
  const blur = generateBlurDataURL(300, 260)
  const { productIds, toggleFavourite } = useLikedProducts()

  const isLiked = productIds.includes(productId)

  return (
    <figure
      className={clsx([css['product-cover']], {
        [css['product-cover--flex']]: layout === 'flex',
        [css['product-cover--grid']]: layout === 'grid',
      })}
    >
      <div className={clsx([css['product-favourite']], 'group')}>
        <Icon
          name={isLiked ? 'heart_full' : 'heart'}
          className={clsx('w-5 h-5 cursor-pointer text-black', {
            'text-red-300': isLiked,
            'text-gray-500 hover:text-red-300': !isLiked,
          })}
          onClick={() => toggleFavourite(productId)}
        />
      </div>
      {src && (
        <Image
          alt={name}
          src={src.replace(/\/([^/]+)$/, '/cover_$1')}
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL={blur}
          className="select-none"
          priority
          quality={75}
          loading="eager"
        />
      )}
    </figure>
  )
}

interface CoverProps {
  src: string
  name: string
  layout: 'flex' | 'grid'
  id?: number
  refetch?: () => void
}

export default memo(Cover)
