import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

function NewnessBadge({ className }: NewnessBadgeProps) {
  const { t } = useTranslation('global')

  return (
    <div
      className={clsx(
        'absolute flex items-center justify-center w-6 h-6 bg-green-100 top-[-8px] left-[-8px] rounded-full p-4 md:p-6 z-50',
        className
      )}
    >
      <p className="text-xs font-semibold text-white md:text-sm">
        {t('badge_new')}
      </p>
    </div>
  )
}

interface NewnessBadgeProps {
  className?: string
}

export default NewnessBadge
