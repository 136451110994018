import Product, { ProductPrice } from '@/ts/Product'

export default function getProductGridPrice(
  product: Partial<Product>
): ProductPrice {
  return (
    product?.entries?.map(({ price }) => price).sort()[0] ??
    product?.price ?? {
      value: 0,
      price_before_discount: 0,
      currency: 'PLN',
    }
  )
}
