import useSliderState from '../functions/useSliderState'
import { NavigationProps } from '../ts'
import Indicator from './Indicator'

function Navigation({ slider, theme }: NavigationProps) {
  const state = useSliderState(slider)
  const { isPreviousScroll, isNextScroll, handleScroll } = state

  return (
    <div className="hidden md:block">
      <Indicator
        theme={theme}
        direction="left"
        onClick={() => handleScroll('prev')}
        isDisabled={!isPreviousScroll}
      />
      <Indicator
        theme={theme}
        direction="right"
        onClick={() => handleScroll('next')}
        isDisabled={!isNextScroll}
      />
    </div>
  )
}

export default Navigation
