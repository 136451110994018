import clsx from 'clsx'
import { memo } from 'react'

import DiscountPrice from '@/atoms/DiscountPrice'
import formatProductPrice from '@/helpers/formatProductPrice'
import getProductGridPrice from '@/helpers/getProductGridPrice'
import Product from '@/ts/Product'

import Details from './includes/Details'
import Header from './includes/Header'

function Informations(props: InformationsProps) {
  const { product, asFeatured = false } = props
  const { creator, name, slug } = product

  const price = getProductGridPrice(product)

  return (
    <div className="flex flex-col flex-1 p-4 bg-white rounded-b-lg xl:p-5">
      <Header creator={creator} name={name} slug={slug} />
      <div className="flex flex-col items-start mt-auto space-y-2 xl:items-end xl:space-y-0 xl:justify-between xl:flex-row">
        <Details asFeatured={asFeatured} product={product} />
        <div className="flex flex-col items-start xl:items-center">
          {price.price_before_discount < price.value ? (
            <span className="text-sm font-medium">
              {formatProductPrice(product)}
            </span>
          ) : (
            <>
              <DiscountPrice
                priceBeforeDiscount={price.price_before_discount}
                price={price.value}
                className="text-xs text-red-300"
              />
              <span
                className={clsx(
                  'text-xs',
                  price.price_before_discount > price.value
                    ? 'text-[16px] font-medium'
                    : 'text-sm font-medium'
                )}
              >
                {formatProductPrice(product)}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

interface InformationsProps {
  product: Product
  asFeatured?: boolean
}

export default memo(Informations)
