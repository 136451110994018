import clsx from 'clsx'

import { FansiKindExtended } from '@/ts/Global'

function FansiMark(props: FansiMarkProps) {
  const { kind, isActive = false, asDot = false } = props

  return (
    <div
      className={clsx('rounded-full h-2.5 w-2.5 flex-shrink-0 transition-all', {
        'w-14 md:w-16': !asDot,
        'w-16': asDot && isActive,
        'bg-violet-200': kind === 'fansi_video',
        'bg-green-10': kind === 'fansi_collection',
        'bg-violet-100': kind === 'digital',
        'bg-green-100': kind === 'fansi_class',
        'bg-sbtyellow-500': kind === 'fansi_experience',
        'bg-green-50': kind === 'fansi_projects',
        'bg-violet-60': kind === 'new',
        'bg-green-60': kind === 'bestsellers',
        'bg-sbtsalmon-600': kind === 'sale',
        'bg-white': kind === 'creators',
      })}
    />
  )
}

export interface FansiMarkProps {
  kind: FansiKindExtended
  isActive?: boolean
  asDot?: boolean
}

export default FansiMark
