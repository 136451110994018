import Product from '@/ts/Product'

import getProductGridPrice from './getProductGridPrice'

type Locale = 'pl' | 'en'

type FormatPrice = (product: Product, locale?: Locale) => string

const formatProductPrice: FormatPrice = (product, locale) => {
  const price = getProductGridPrice(product)

  const grossedPrice = price.value / 100
  const formattedPrice = new Intl.NumberFormat(locale ?? 'pl', {
    style: 'currency',
    currency: price.currency,
  }).format(grossedPrice)
  return product.type === 'Product::FansiExperience'
    ? `od ${formattedPrice}`
    : formattedPrice
}
export default formatProductPrice
