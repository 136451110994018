import useTranslation from 'next-translate/useTranslation'

import FansiMark from '@/atoms/FansiMark'
import Link from '@/atoms/Link'
import PRODUCT_AS_FANSI_KIND from '@/constants/productAsFansiKind'
import PRODUCT_AS_URL from '@/constants/productAsUrl'
import Product from '@/ts/Product'

function Type({ type }: TypeProps) {
  const { t } = useTranslation('global')

  const href = '/products/' + PRODUCT_AS_URL[type]
  const kind = PRODUCT_AS_FANSI_KIND[type]

  return (
    <Link
      className="z-20 flex items-center space-x-2 text-sm font-medium leading-normal hover:underline"
      href={href}
    >
      <FansiMark kind={kind} asDot />
      <span className="pt-0.5">{t(kind)}</span>
    </Link>
  )
}

interface TypeProps {
  type: Product['type']
}

export default Type
