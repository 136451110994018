import ProductType from '@/ts/enums/product/ProductType'

const PRODUCT_AS_URL: Record<ProductType, string> = {
  'Product::Digital': 'digital',
  'Product::FansiClass': 'fansi-class',
  'Product::FansiCollection': 'fansi-collections',
  'Product::FansiVideo': 'fansi-video',
  'Product::FansiExperience': 'fansi-experience',
}

export default PRODUCT_AS_URL
