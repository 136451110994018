import { EmblaCarouselType } from 'embla-carousel/embla-carousel-vanilla'
import { useEffect, useState } from 'react'

function useSliderState(slider?: EmblaCarouselType) {
  const [isPreviousScroll, setPreviousScroll] = useState(false)
  const [isNextScroll, setNextScroll] = useState(false)

  const handleScroll = (direction: 'prev' | 'next') => {
    if (slider) {
      const x = slider.selectedScrollSnap()
      const y = slider.slidesInView().length

      if (direction === 'prev') return slider.scrollTo(x - y)

      slider.scrollTo(x + y)
    }
  }

  const onSelect = () => {
    if (!slider) return

    setPreviousScroll(slider.canScrollPrev())
    setNextScroll(slider.canScrollNext())
  }

  useEffect(() => {
    if (!slider) return
    slider.on('select', onSelect)
    onSelect()
  }, [slider, onSelect])

  useEffect(() => {
    if (!slider) return
    slider.on('resize', () => onSelect())
  }, [slider])

  return { isPreviousScroll, isNextScroll, handleScroll }
}

export default useSliderState
