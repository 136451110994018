function Base({ category }: BaseProps) {
  return (
    <span className="max-w-full text-sm truncate text-black/60">
      {category}
    </span>
  )
}

interface BaseProps {
  category: string
}

export default Base
