enum BaseRoute {
  Basket = '/basket/',
  Checkout = '/checkout/',
  Creators = '/creators/',
  Contact = '/contact/',
  ForBrands = '/for-brands/',
  ForCreators = '/for-creators/',
  Products = '/products/',
}

export default BaseRoute
