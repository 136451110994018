import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

function BestsellersBadge({ className }: BestsellersBadgeProps) {
  const { t } = useTranslation('global')

  return (
    <div
      className={clsx(
        'flex items-center justify-center bg-green-60 rounded-b-md',
        className
      )}
    >
      <p className="py-2 text-sm font-semibold text-sbtgray-100">
        {t('badge_bestseller')}
      </p>
    </div>
  )
}

interface BestsellersBadgeProps {
  className?: string
}

export default BestsellersBadge
