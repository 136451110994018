import { memo } from 'react'

import Link from '@/atoms/Link'
import CreatorReference from '@/molecules/CreatorReference'
import css from '@/molecules/ProductCard/css/index.module.css'
import Creator from '@/ts/Creator'

function Header(props: HeaderProps) {
  const { creator, name, slug } = props

  return (
    <div className="flex flex-col mb-6 space-y-3">
      <div className="z-20 max-w-max">
        <CreatorReference creator={creator} />
      </div>
      <h3 className={css['product-name']} title={name}>
        <Link
          href={`/products/${slug}`}
          className="text-sm font-medium hover:underline"
        >
          {name}
        </Link>
      </h3>
    </div>
  )
}

interface HeaderProps {
  creator: Creator
  name: string
  slug: string
}

export default memo(Header)
