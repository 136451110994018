import ProductCard from '@/molecules/ProductCard'
import Slider from '@/organisms/Slider'

import { ProductSliderProps } from './ts'

function ProductSlider(props: ProductSliderProps) {
  const { products, asFeatured } = props

  if (products.length <= 0) return null

  return (
    <Slider className="pb-6 text-black">
      {products.map((product) => (
        <article
          className="flex-shrink-0 mt-2 w-full max-w-[220px] xl:max-w-[260px]"
          key={product.id}
        >
          <ProductCard
            asFeatured={asFeatured}
            product={product}
            layout="flex"
          />
        </article>
      ))}
    </Slider>
  )
}

export default ProductSlider
