import clsx from 'clsx'
import { useEmblaCarousel } from 'embla-carousel/react'

import Navigation from './include/Navigation'
import css from './scss/index.module.scss'
import { SliderProps } from './ts'

function Slider({ className, theme = 'white', children }: SliderProps) {
  const [ref, slider] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    selectedClass: '',
    draggableClass: '',
    draggingClass: '',
  })

  return (
    <div className={clsx('paded-layout relative overflow-hidden', className)}>
      <div className="relative">
        <div className={css.slider} ref={ref}>
          <div className="flex space-x-4 md:space-x-6">{children}</div>
        </div>
        <Navigation slider={slider} theme={theme} />
      </div>
    </div>
  )
}

export default Slider
