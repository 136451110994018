import { memo } from 'react'

import Link from '@/atoms/Link'
import BaseRoute from '@/ts/enums/routes/BaseRoute'
import Product from '@/ts/Product'

import BestsellerBadge from './includes/BestsellerBadge'
import Cover from './includes/Cover'
import Informations from './includes/Informations'
import NewnessBadge from './includes/NewnessBadge'

function ProductCard(props: ProductCardProps) {
  const { product, asFeatured = false, layout = 'grid' } = props
  const { cover, name, slug, id } = product
  const href = BaseRoute.Products + slug

  return (
    <div className="relative w-full h-full">
      {product.newness === true && <NewnessBadge />}
      <div
        className="relative flex flex-col min-h-full overflow-hidden rounded-lg border-black/5"
        style={{
          boxShadow:
            'rgba(0, 0, 0, 0.03) 0px 4px 6px 1px, rgba(0, 0, 0, 0.03) 0px 2px 4px 1px',
        }}
      >
        <Link className="absolute top-0 left-0 z-10 w-full h-full" href={href}>
          <span className="sr-only">Go to product</span>
        </Link>
        <Cover
          src={cover}
          name={name}
          layout={layout}
          id={id}
          refetch={props.refetch}
        />
        {product.bestseller === true && <BestsellerBadge />}
        <Informations product={product} asFeatured={asFeatured} />
      </div>
    </div>
  )
}

interface ProductCardProps {
  product: Product
  layout?: 'flex' | 'grid'
  asFeatured?: boolean
  refetch?: () => void
}

export default memo(ProductCard)
