import Image from 'next/image'

import generateBlurDataURL from '@/helpers/generateBlurDataURL'

import { AvatarProps } from './ts'

function Avatar({ nickname, src, size = 16 }: AvatarProps) {
  const blur = generateBlurDataURL(size, size)

  return (
    <div
      className="relative flex-none overflow-hidden rounded-full"
      style={{ height: size, width: size }}
    >
      <Image
        src={src.replace(/\/([^/]+)$/, '/mini_$1')}
        alt={nickname}
        priority
        layout="fill"
        objectFit="cover"
        placeholder="blur"
        blurDataURL={blur}
        quality={75}
        loading="eager"
      />
    </div>
  )
}

export default Avatar
