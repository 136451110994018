type Currency = 'PLN' | 'EUR' | 'USD'
type Locale = 'pl' | 'en'

type FormatPrice = (
  price: number,
  currency?: Currency,
  locale?: Locale
) => string

const formatPrice: FormatPrice = (price, currency = 'PLN', locale = 'pl') => {
  const grossedPrice = price / 100
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(grossedPrice)
  return formattedPrice
}
export default formatPrice
