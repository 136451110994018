import clsx from 'clsx'

import formatPrice from '@/helpers/formatPrice'

interface DiscountPriceProps {
  price: number | string
  priceBeforeDiscount: number
  quantity?: number
  className?: string
}

export default function DiscountPrice({
  price,
  priceBeforeDiscount,
  quantity = 1,
  className,
}: DiscountPriceProps) {
  return priceBeforeDiscount > 0 && priceBeforeDiscount !== price ? (
    <span className={clsx(className, 'text-gray-100 line-through')}>
      {formatPrice(priceBeforeDiscount * quantity)}
    </span>
  ) : (
    <></>
  )
}
