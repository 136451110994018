import { memo } from 'react'

import Product from '@/ts/Product'

import Base from './includes/Base'
import Duration from './includes/Duration'
import Type from './includes/Type'

function Details({ product, asFeatured }: DetailsProps) {
  const { categories, duration, type } = product

  const category = categories?.[0]?.category?.name

  if (asFeatured) return <Type type={type} />

  if (type === 'Product::FansiClass') return <Duration seconds={duration} />

  return <Base category={category} />
}

interface DetailsProps {
  product: Product
  asFeatured: boolean
}

export default memo(Details)
