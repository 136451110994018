import IconClock from '@/assets/svg/purge/icons/icon-clock.svg'
import dayjs from '@/helpers/dayjs'

function Duration({ seconds }: DurationProps) {
  const duration = dayjs.duration(seconds, 'seconds')
  const format = duration.hours() >= 1 ? 'H[h] m[min]' : 'm[min]'
  const value = duration.format(format)

  return (
    <div className="flex items-center flex-shrink-0 space-x-2 text-xs text-gray-100">
      <span className="block w-4 h-4">
        <IconClock />
      </span>
      <span className="pt-0.5">{value}</span>
    </div>
  )
}

interface DurationProps {
  seconds: number
}

export default Duration
