import clsx from 'clsx'

import Arrow from '@/atoms/Arrow/Arrow'

import { IndicatorProps } from '../ts'

function Indicator(props: IndicatorProps) {
  const { direction, theme, onClick, isDisabled } = props

  if (isDisabled) return null

  return (
    <button
      className={clsx(
        'flex items-center absolute top-1/2 -translate-y-1/2 justify-center h-10 w-12 rounded-sm focus:outline-none',
        {
          'bg-white': theme === 'white',
          'bg-sbtviolet-550 text-white': theme === 'violet',
          'left-0': direction === 'left',
          '2xl:right-[calc((100%-1680px+8rem)/2)] 3xl:right-[calc((100%-1680px)/2)] right-0':
            direction === 'right',
        }
      )}
      onClick={onClick}
      disabled={isDisabled}
      style={{
        boxShadow: 'rgba(100,100,100,0.15) 0px 7px 29px 0px',
      }}
    >
      <span className="block w-5 xl:w-6">
        <Arrow direction={direction} variant="primary" />
      </span>
    </button>
  )
}

export default Indicator
